import {
    userSlice,
    UserState,
    getUserInitials
} from '@thriveglobal/thrive-web-core'
import { CombinedState } from 'redux'
import { createSelector } from '@reduxjs/toolkit'

export const userSliceName = userSlice.name

export type RootStateWithUserState = CombinedState<{
    [userSliceName]: UserState
}>

export const selectUserState = (
    rootState: RootStateWithUserState
): UserState => {
    return rootState[userSliceName]
}

export const selectUserCompanyId = createSelector(
    selectUserState,
    (userState) => userState.companyId
)
export const selectUserId = createSelector(
    selectUserState,
    (userState) => userState.userId
)

export const selectJourneyFeedProfile = createSelector(
    selectUserState,
    (userState) => {
        const { fullName, userProfileImageUrl } = userState

        const initials = getUserInitials(fullName)

        return {
            initials,
            profileImageUrl: userProfileImageUrl,
            fullName: fullName
        }
    }
)
